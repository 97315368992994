.pool {
  display: grid;
  grid-row-gap: 8px;
}

.pool div {
  line-height: 40px;
  height: 40px;
  text-align: center;
}

.legend {
  color: var(--light-text);
}

.black {
  background-color: var(--text);
}

.win {
  background-color: var(--highlight);
}

.name {
  background-color: var(--background);
}

.App {
  width: 100%;
}

.body {
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}

@media screen and (min-width: 601px) {
  .body {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}

.games {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 8px;
  grid-template-columns: 40px 3fr repeat(2, 1fr) 3fr 40px;
  line-height: 40px;
  text-align: center;
}

.participants {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 8px;
}

.participant {
  background-color: var(--background);
  border: 2px solid var(--border);
}

.selected {
  background-color: var(--border);
}

.teamIndex {
  height: 88px;
  line-height: 88px;
}

.members,
.score,
.button {
  display: flex;
  flex-direction: column;
  height: 88px;
  line-height: 88px;
  justify-content: space-evenly;
}

.members div {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
}

.tournament {
  grid-column: span 3;
}

@media screen and (min-width: 601px) {
  .Games {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .participants {
    margin-bottom: 8px;
  }
}

.Pool form {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 8px;
  text-align: center;
}

input.selectedInput {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--background);
}

input.lp0,
input.lp3 {
  grid-column-start: 1;
}

input.rp0,
input.rp3 {
  grid-column-start: 3;
}

.lpFiller{
  grid-column: 4 / 6;
}

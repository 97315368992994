.PersonForm form {
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: 1fr 3fr;
  grid-row-gap: 8px;
}

.PersonForm label {
  text-align: right;
}

.buttonGroup {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.PersonForm input[type=button]:disabled {
  background-color: var(--border);
  color: var(--text);
  transition: 0.3s;
}

.PersonForm input[type=submit] {
  grid-column-start: 2;
}

.PersonForm input[type=submit]:not(:disabled) {
  background-color: var(--blue);
  color: var(--background);
}

.text {
  padding-left: 4px;
}

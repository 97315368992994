.Attendance form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
}

.Attendance input {
  display: block;
  margin-bottom: 8px;
  width: 100%;
}

.Attendance button {
  background-color: var(--highlight);
  display: grid;
  grid-column: 1 / span 6;
  grid-template-columns: 40px 1fr 40px;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
}

.Attendance button.branch {
  background-color: var(--border);
}

.Attendance button div {
  line-height: var(--button-height);
}

.Attendance input[type="button"].came {
  background-color: var(--border);
  transition: 0.3s;
}

.Attendance input[type="submit"] {
  grid-column-start: 1;
  grid-column: span 6;
}

.Attendance a,
.Attendance input[type="reset"] {
  grid-column: span 3;
}

.Attendance input[type="reset"]:not(:disabled) {
  background-color: var(--highlight);
}

.Attendance input[type="submit"] {
  background-color: var(--blue);
  color: var(--background);
}

.svg {
  fill: var(--text);
}

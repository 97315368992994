.IndividualGameForm form {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
}

.IndividualGameForm label {
  background-color: var(--highlight);
  text-align: center;
}

.noInput {
  color: var(--light-text);
}

input.selectedInput {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--background);
}

input.delete {
  background-color: var(--highlight);
  border-color: var(--highlight);
  color: var(--red);
  grid-column: span 4;
}

.IndividualGameForm input[type=submit],
.personSelect,
.pointInput {
  grid-column: span 4;
}

.pointInput {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
}

.pointInputDivider {
  background-color: var(--highlight);
  grid-column: span 4;
  text-align: center;
}

@media screen and (min-width: 601px) {
  .IndividualGameForm input {
    height: 80px;
  }
}

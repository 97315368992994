.Elimination {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 40px 3fr 1fr 1fr 3fr 40px;
  text-align: center;
}

.Elimination div {
  height: var(--button-height);
  line-height: var(--button-height);
}

.round {
  background-color: var(--highlight);
  grid-column: span 6;
}

.Elimination label.label {
  background-color: var(--highlight);
}

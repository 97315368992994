.FinalRank {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 40px 1fr);
}

.FinalRank div {
  height: var(--button-height);
  line-height: var(--button-height);
}

.rank {
  background-color: var(--highlight);
  border-radius: 20px;
  text-align: center;
}

.name {
  margin-left: 8px;
}

.RankTable {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 1fr);
  line-height: var(--button-height);
  text-align: center;
}

.title {
  grid-column: span 6;
}

.button {
  background-color: var(--background);
  border: 2px solid var(--border);
}

.button:disabled {
  background-color: var(--border);
}

.label {
  grid-column-start: 1;
}

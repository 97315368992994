.LinkButton {
  background-color: var(--highlight);
  display: block;
  height: var(--button-height);
  line-height: var(--button-height);
  text-align: center;
  width: 100%;
}

.md,
.lg {
  margin-bottom: 8px;
}

@media screen and (min-width: 601px) {
  .lg {
    height: var(--large-button-height);
    line-height: var(--large-button-height);
  }
}

.Header {
  background-color: var(--light-text);
  color: var(--background);

  display: block;
  font-size: 24px;
  line-height: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
}

.content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.swapButton {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: -3px;
}

.buttonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 3px;
  justify-items: end;
}

.button {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 601px) {
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    grid-template-columns: 0 auto 1fr;
    text-align: left;
    padding: 0 8px;
  }
}

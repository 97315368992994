.ResponsiveTabsLarge {
  display: grid;
  grid-column-gap: 8px;
}

.tabs {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: 8px;
  margin-bottom: 16px;
}

.notSelectedTab {
  border: 2px solid var(--border);
  background-color: var(--background);
}

.selectedTab {
  background-color: var(--border);
}

.Rank {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 40px repeat(4, 1fr);
}

.Rank div {
  line-height: 40px;
  height: 40px;
  text-align: center;
}

.legend {
  color: var(--light-text);
}

.name {
  background-color: var(--background);
}

.rank {
  background-color: var(--highlight);
  border-radius: 20px;
}

.EditTeamGame form {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 8px;
  text-align: center;
}

.EditTeamGame label.header {
  background-color: var(--highlight);
}

.EditTeamGame label {
  height: var(--button-height);
  line-height: var(--button-height);
}

input.selectedInput {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--background);
}

.personSelect,
.pointInput,
.EditTeamGame input[type="submit"],
.delete {
  grid-column: span 7;
}

.deleteRow {
  grid-column: span 3;
}

.addRow {
  grid-column: span 4;
}

@media screen and (min-width: 601px) {
  .EditTeamGame label {
    height: 60px;
    line-height: 60px;
  }

  .EditTeamGame label.header {
    height: var(--button-height);
    line-height: var(--button-height);
  }

  .EditTeamGame input {
    height: 60px;
    line-height: 56px;
  }
}

@media screen and (max-width: 600px) {
  .EditTeamGame,
  .EditTeamGame input,
  .EditTeamGame label {
    font-size: 16px;
  }

  .EditTeamGame label {
    height: 30px;
    line-height: 30px;
  }

  .EditTeamGame input {
    height: 30px;
    line-height: 26px;
  }
}

.ChooseTeamMembers,
.ChooseTeamOrder {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
}

.label {
  grid-column-start: 1;
  text-align: center;
}

.AddTeamGame input[type=button]:disabled {
  background-color: var(--border);
  color: var(--text);
  transition: 0.3s;
}

.ChooseTeamMembers input[type=submit],
.ChooseTeamOrder input[type=submit],
.personSelect {
  grid-column: span 5;
}

input.selected {
  background-color: var(--border);
}

.gameOrder {
  display: grid;
  grid-column: span 4;
  grid-template-columns: repeat(12, 1fr);
}

.gameOrderLabel {
  background-color: var(--highlight);
}

.gameOrderLabel,
.gameOrder div,
.label {
  height: var(--button-height);
  line-height: var(--button-height);
}

@media screen and (min-width: 601px) {
  .AddTeamGame input {
    height: 88px;
  }

  .label {
    height: 88px;
    line-height: 88px;
  }
}

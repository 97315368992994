.byPerson {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 2fr 40px;
  grid-auto-rows: var(--button-height);
  line-height: var(--button-height);
  text-align: center;
}

.byPerson .legend {
  grid-column: 1 / 4;
  background-color: var(--border);
}

.byDate {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: var(--button-height);
  line-height: var(--button-height);
  text-align: center;
}

.result {
  text-align: right;
}

@media (max-width: 600px) {
  .byPerson {
    grid-template-columns: 1fr 2fr 40px;
  }
  .byDate {
    grid-template-columns: 1fr 1fr;
  }
}

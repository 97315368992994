.PersonSelect {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

.PersonSelect input,
.label {
  display: block;
  margin-bottom: 8px;
  width: 100%;
}

.label {
  background-color: var(--highlight);
  text-align: center;
}

.wideColumn {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.wideColumn .label {
  grid-column: 1 / span 2;
}
